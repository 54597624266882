<template>
  <div class="loading-screen">
    <v-card flat>
      <v-card-text class="text-center">
        <div v-if="!error">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div class="text-h5 pt-4">{{ text  }}</div>
        </div>
        <div v-if="error" class="ma-4">
          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>
          <v-btn link x-large @click="logout" color="primary">
            {{ $t('logout') }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AuthService from '@/services/auth.service.js'

export default{
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: Error,
      default: null
    },
    text: {
      type: String,
      default: 'Loading...'
    }
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated', 'user']),
    // isLoggedIn(){
    //   return this.isAuthenticated || configService.config.
    // }
  },
  methods: {
    ...mapActions('user', ['clearUser']),
    async logout(){
      await this.clearUser()
      await AuthService.logout()
    }
  }
}
</script>
<style scoped>
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
}
</style>
